import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from "gatsby"


const ImageGallery = (props) => {

	const data = useStaticQuery(
		graphql`query {
	images: allFile(filter: {sourceInstanceName: {eq: "branding"}}, sort: {order: ASC, fields: relativePath}) {
		edges {
		  node {
			relativePath
			base
		  }
		}
	}
}
`)


	const galleryImages = data.images.edges.filter(edge =>
		edge.node.relativePath.startsWith(props.foldername)
	)

	return (
		<div className='design-grafico-images'>
			{galleryImages.map((image, i) => {
				let url = "/branding/" + image.node.relativePath;
				if (i > 2) {
					return (<img className='design-grafico__image animated' index={i} key={i} src={url} alt={props.foldername} loading="lazy" data-scroll></img>);
				}
			})}
		</div>
	)
}


ImageGallery.propTypes = {
	foldername: PropTypes.string,
	bgColor: PropTypes.string
};

export default ImageGallery;